// extracted by mini-css-extract-plugin
export var almostFull = "BootcampSessionCard-module--almost-full--3mUvg";
export var applyButtonWrapper = "BootcampSessionCard-module--apply-button-wrapper--6Nzjz";
export var banner = "BootcampSessionCard-module--banner--X3sCv";
export var card = "BootcampSessionCard-module--card--D1kGK";
export var icon = "BootcampSessionCard-module--icon--v62qX";
export var locationType = "BootcampSessionCard-module--location-type--YdDrY";
export var sessionDates = "BootcampSessionCard-module--session-dates--OCe5a";
export var sessionDetails = "BootcampSessionCard-module--session-details--oco8p";
export var soldOut = "BootcampSessionCard-module--sold-out--Isz+F";
export var withBanner = "BootcampSessionCard-module--with-banner--5WvxO";